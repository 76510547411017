body {
    direction: rtl;
}

.linkLine {
    color: cornflowerblue;
    font-weight: bold;
    cursor: pointer;
}

.pole-page-logo {
    height: 100px;
}

.fotter-button-custom {
    color: #686565 !important;
    text-decoration: none;
}
